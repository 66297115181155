/* eslint-disable @typescript-eslint/no-invalid-this */
/* global $, gsap */
import "swiper/swiper-bundle.min.css";

import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";
window.whintegration = whintegration;
import "@mod-system/js/wh/integration";
import "dompack/browserfix/reset.css";
import * as util from "./shared/js/utilities";
import { registerHandler } from "@mod-publisher/js/forms";
import * as cookie from "dompack/extra/cookie";
import * as dialogapi from "dompack/api/dialog";

import "./shared/rtd/rtd";
import "./shared/forms/forms";

import "./shared/bem/button.scss";
import "./shared/bem/accordion.scss";
import "./shared/bem/badge.scss";
import "./shared/bem/blockquote.scss";
import "./shared/bem/crumbpath.scss";
import "./shared/bem/hamburger.scss";
import "./shared/bem/roundnavbutton.scss";
import "./shared/bem/topbar.scss";

import "./at_groep.scss";

import { openLinksInNewWindow } from "@mod-publisher/js/linkhandler";
openLinksInNewWindow();

// components & widgets
import "./widgets/widgets";
import "./components/contact/contact.scss";
import "./components/card/card";
import Cards from "./components/cards/cards";
import "./components/headermenu/headermenu";
import * as modal from "./components/modal/modal";
import "./components/newsblock/newsblock.scss";
import NewsletterForm from "./components/newsletter/newsletter";
import "./components/scrolltotop/scrolltotop";
import SideBarContact from "./components/sidebarcontact/sidebarcontact";
import "./components/slideshow/slideshow";
import WidgetServices from "./widgets/w-services/w-services";
import WidgetQuotes from "./widgets/w-quotes/w-quotes";
import WidgetVacancies from "./widgets/w-vacancies/w-vacancies";
import WidgetServiceCards from "./widgets/at.groep/servicecards/servicecards";
import * as mobilemenu from "./mobilemenu/mobilemenu";

// pages
import HomeScrollDown from "./pages/home/home";
import PageOverview from "./pages/overview/overview";
import "./pages/search/search";
import "./pages/vacancies/vacancies";
import "./pages/contact/contact.scss";

// vendor

// window.jQuery = $;
// require('stacktable.js');
import "aos/dist/aos.css";
import AOS from "aos";
import barba from "@barba/core";
import Dialog from "share-dialog";

dompack.onDomReady(() => {
  setupBarba();
  onPageLoad();

  if (dompack.debugflags.removecontactsidebar) $(".sidebarcontact").remove();

  if (whintegration.config.site.is_new_care_site === true) {
    // setupZorgMailChangedModal();
  } else {
    setupDjoppzzHeader();
  }

  $.fn.removeClassPrefix = function (prefix) {
    this.each(function (i, el) {
      let classes = el.className.split(" ").filter(function (c) {
        return c.lastIndexOf(prefix, 0) !== 0;
      });
      el.className = $.trim(classes.join(" "));
    });
    return this;
  };
});

// wait a max of 500 ms to show the page
window.setTimeout(() => {
  $("html").addClass("initialized");
}, 500);

window.addEventListener("load", (event) => {
  $("html").addClass("initialized");
});

// called when new page transition is done (AND on first pageload!), eg dom is ready
function onPageLoad() {
  dompack.register(".cards", (node) => new Cards(node));
  dompack.register(".newsletterform", (node) => new NewsletterForm(node));
  dompack.register(".sidebarcontact", (node) => new SideBarContact(node));
  dompack.register(".p-overview", (node) => new PageOverview(node));
  dompack.register(".w-services", (node) => new WidgetServices(node));
  dompack.register(".w-servicecards", (node) => new WidgetServiceCards(node));
  dompack.register(".w-quotes", (node) => new WidgetQuotes(node));
  dompack.register(".w-vacancies", (node) => new WidgetVacancies(node));
  dompack.register(".js-home-scrolldown", (node) => new HomeScrollDown(node));
  dompack.registerMissed($(".wh-video").get(0));

  registerHandler(
    "at_groep:newsletterform",
    (node) => new NewsletterForm(node)
  );

  mobilemenu.initialize();
  setupButtons();
  setupMobileSearch();
  setupMobileNews();
  setupNews();
  setupHeaderMenu();
  setupHeaderSearch();
  setupVacancyFormModal();
  setupSharePage();
  // setupShareSiteRoot();
  setupTables();

  const numMainItems = $("main > *").length;
  $("main > *").each(function (idx) {
    $(this).css("z-index", numMainItems - idx);
  });

  if (util.iOS()) $("html").addClass("ios");

  const disableAOS =
    $("html").width() <= 576 || $("html").hasClass("page-search");

  AOS.init({
    offset: document.documentElement.clientHeight > 800 ? 100 : 0,
    duration: 700,
    delay: 100,
    disable: disableAOS,
  });
}

function setupDjoppzzHeader() {
  const hasClosedHeader = cookie.read("at-djopzz-hide-header") === "1";

  $(".js-close-djopzzheader").click((evt) => {
    evt.preventDefault();
    cookie.write("at-djopzz-hide-header", "1", { duration: 365 });
    $("html").removeClass("has-djopzz-header");
  });

  if (!hasClosedHeader || location.href.includes("djopzzheader=1")) {
    document.documentElement.classList.add("has-djopzz-header");
  }

  if (location.href.includes("djopzzheader=1")) {
    cookie.remove("at-djopzz-hide-header");
  }
}

// async function setupZorgMailChangedModal() {
//   if (location.href.includes("showzorgmailchanged=1")) {
//     cookie.remove("at-zorg-hide-mail-changed-header");
//   }

//   const hasClosedModal = cookie.read("at-zorg-hide-mail-changed-header") == "1";

//   const $content = $(
//     `<div><h3>Nieuwe mailadressen</h3><p>Let op: per 9 februari 2024 wijzigen onze mailadressen van ...@at-groep.nl naar ...@at-zorg.nl</p></div>`
//   );

//   if (!hasClosedModal || location.href.includes("showzorgmailchanged=1")) {
//     await dialogapi.runMessageBox($content.get(0), [], {});
//     cookie.write("at-zorg-hide-mail-changed-header", "1", { duration: 365 });
//   }
// }

function setupButtons() {
  $(".wh-form__button").addClass("button--primary");

  $("button, .button, .wh-form__button").each(function () {
    if ($(this).find(".button__label").length > 0) {
      // already "handled"
      return;
    }

    if (!$(this).hasClass("button--primary")) {
      $(this).addClass("button--secondary");
    }

    const label = $(this).text();
    $(this)
      .html("")
      .html(
        `<span class="button__label text-truncate">${label}</span>` +
        '<span class="button__arrowright">' +
        $("#svgicon-arrow-right").html() +
        "</span>"
      );

    // icons are 'hidden' in the HTML, see at_groep.witty
    const icon = $(this).data("icon");
    if (icon) {
      $(this)
        .addClass("button--icon")
        .append(
          '<span class="button__icon">' +
          $(`#svgicon-${icon}`).html() +
          "</span>"
        );
    }
  });
}

function setupMobileSearch() {
  $(".topbar__mobilesearchform .wh-form__textinput").attr("type", "search");

  // $('.topbar__mobilesearchform .wh-form .wh-form__textinput').on('blur', function() {
  //   toggleMobileSearch();
  // });

  $(".js-toggle-mobile-search").on("click", function (evt) {
    evt.preventDefault();
    toggleMobileSearch();
  });
}

function toggleMobileSearch() {
  const wasVisible = $("html").hasClass("mobile-search-open");

  $("html").toggleClass("mobile-search-open");

  closeNewsMobile();

  if (!wasVisible) {
    $(".topbar__mobilesearchform .wh-form .wh-form__textinput").focus();
  }
}

function setupNews() {
  $(".js-toggle-news").on("click", function (evt) {
    evt.preventDefault();
    toggleNews();
  });
  $(".news").addClass("initialized");
}

function toggleNews() {
  const openingMenu = !$("html").hasClass("news-open");
  $("html").toggleClass("news-open");

  const button = $("#opennewsmodal");
  if (!button.length) return;

  if (openingMenu) {
    $("html").removeClass("search-open").removeClass("menu-open");
  } else {
    closeNews();
  }
}

function closeNews() {
  $("html").removeClass("menu-open");
}

function setupMobileNews() {
  $(".js-toggle-mobile-news").on("click", function (evt) {
    evt.preventDefault();
    toggleMobileNews();
  });

  $(".mobilenews").addClass("initialized");
}

function toggleMobileNews() {
  const openingMenu = !$("html").hasClass("mobile-news-open");
  $("html").toggleClass("mobile-news-open");

  if (openingMenu) {
    $("html").removeClass("mobile-search-open");
    $("html").removeClass("mobile-menu-open");
  } else {
    closeNewsMobile();
  }
}

function closeNewsMobile() {
  $("html").removeClass("mobile-news-open");
}

function setupHeaderMenu() {
  $(".headermenu__sublink").append(
    `<span class="headermenu__sublink-icon">${$(
      "#svgicon-arrow-right"
    ).html()}</span>`
  );
}

function setupBarba() {
  barba.init({
    prevent: ({ el }) => el.classList && !el.classList.contains("barbalink"), // only activate Barba for links containing the "barbalink" class
    transitions: [
      {
        name: "default-transition",
        leave(data) {
          return gsap.fromTo(
            ".pageloadoverlay",
            {
              opacity: 0,
            },
            {
              "pointer-events": "auto",
              opacity: 1,
              duration: 1,
            }
          );
        },
        afterEnter(data) {
          // activate the flip effect
          $(".pageloadoverlay__logocontainer").addClass("flip");

          return gsap.fromTo(
            ".pageloadoverlay",
            {
              opacity: 1,
            },
            {
              opacity: 0,
              "pointer-events": "none",
              delay: 1,
              duration: 1,
            }
          );
        },
      },
    ],
  });

  barba.hooks.beforeEnter((data) => {
    // activate the correct 'back' logo (the next/target site)
    // console.log('activate back:', data.next.namespace);
    $(`.pageloadoverlay__logo--back.${data.next.namespace}`).addClass("active");

    // set proper HTML class
    // console.log('set site class', data.next.namespace);
    $("html").removeClassPrefix("site-");
    $("html").addClass(`site-${data.next.namespace}`);

    // $(`.pageloadoverlay__logo--back.${data.next.namespace}`).addClass('active');
    // remove the current HTML, making room for the next
    $(data.current.container).remove();
    mobilemenu.closeMenu();
    $("html").removeClass("mobile-menu-open mobile-search-open");
    window.scrollTo(0, 0);

    // fire all domready functions
    onPageLoad();
  });

  barba.hooks.after((data) => {
    // remove the flip effect
    $(".pageloadoverlay__logocontainer").removeClass("flip");

    // disable all logos, both front and back
    // console.log('disable all logos');
    $(
      ".pageloadoverlay__logo--front, .pageloadoverlay__logo--back"
    ).removeClass("active");

    // enable the correct front one
    // console.log('activate front:', data.next.namespace);
    $(`.pageloadoverlay__logo--front.${data.next.namespace}`).addClass(
      "active"
    );
  });
}

async function setupVacancyFormModal() {
  $(".js-show-vacancyform, .p-vacancydetails .card .card__button").click(
    async function (evt) {
      evt.preventDefault();
      await modal.runModal($("#vacancyform").get(0), {
        beforeOpen: function (dialog) {
          $(dialog).find(".modal").addClass("modal--vacancy");
        },
        borrow: "#vacancyform",
      });
    }
  );

  // $('.js-show-vacancyform, .p-vacancydetails .card .card__button').first().trigger('click'); // enable to open immediately (for styling)
}

function setupHeaderSearch() {
  const $searchContainer = $(".js-header-search-container");
  const $input = $searchContainer.find("input");
  const $form = $searchContainer.find("form");

  const htmlClass = "showsearchinput";

  $input.blur(function (evt) {
    // check where the user clicked on; if it's the search icon, don't do anything (eg let the event through)
    if (
      evt.relatedTarget &&
      evt.relatedTarget.classList &&
      evt.relatedTarget.classList.contains("js-toggle-header-search")
    )
      return;

    $("html").removeClass(htmlClass);
  });

  $(".js-toggle-header-search").click(function (evt) {
    evt.preventDefault();

    // if already active, check input and submit if filled in (if not filled in, just close the input)
    if ($("html").hasClass(htmlClass)) {
      if ($input.val() == "") {
        $("html").removeClass(htmlClass);
        return;
      }

      $form.get(0).submit();
    }
    // not active yet: activate in and give the input focus
    else {
      $("html").addClass(htmlClass);
      $input.focus();
    }
  });
}

function setupSharePage() {
  $(".js-share-page-twitter").click(function (evt) {
    shareEvent(evt, whintegration.config.obj.fileurl, "twitter");
  });
  $(".js-share-page-whatsapp").click(function (evt) {
    shareEvent(evt, whintegration.config.obj.fileurl, "whatsapp");
  });
  $(".js-share-page-linkedin").click(function (evt) {
    shareEvent(evt, whintegration.config.obj.fileurl, "linkedin");
  });
  $(".js-share-page-facebook").click(function (evt) {
    shareEvent(evt, whintegration.config.obj.fileurl, "facebook");
  });
}

// function setupShareSiteRoot() {
//   $('.js-share-siteroot-twitter'). click(function(evt) { shareEvent(evt, whintegration.config.siteroot, 'twitter'); });
//   $('.js-share-siteroot-whatsapp').click(function(evt) { shareEvent(evt, whintegration.config.siteroot, 'whatsapp'); });
//   $('.js-share-siteroot-linkedin').click(function(evt) { shareEvent(evt, whintegration.config.siteroot, 'linkedin'); });
//   $('.js-share-siteroot-facebook').click(function(evt) { shareEvent(evt, whintegration.config.siteroot, 'facebook'); });
// }

function shareEvent(evt, url, type) {
  if (evt) evt.preventDefault();

  switch (type) {
    case "twitter":
      Dialog.twitter(url, whintegration.config.obj.filetitle).open();
      break;
    case "facebook":
      Dialog.facebook(url).open();
      break;
    case "linkedin":
      Dialog.linkedIn(url, whintegration.config.obj.filetitle).open();
      break;
    case "whatsapp":
      window.open("https://wa.me/?text=" + encodeURIComponent(url));
      break;
  }
}

function setupTables() {
  $(".wh-rtd__table").addClass(
    "table table-bordered table-striped table-head table-responsive"
  );
  // $('.wh-rtd__table.responsive_table').stacktable();
}

window.setupButtons = setupButtons;
